import {adminRoutes} from '@/modules/Admin/Routes/adminRoutes.js'
import {routesByName} from '@/modules/Shared/Helpers/routesHelper.js'

const routes = routesByName(adminRoutes)

export default [
  {
    title: "admin.platform.title",
    subtitle: "admin.platform.subtitle",
    items: [
      {
        icon: "content",
        route: routes['admin-translations']
      }
    ]
  },
  {
      title: "admin.apps.title",
      subtitle: "admin.apps.subtitle",
      items: [
          {
              icon: "interstitial_banner",
              route: routes['admin-app-features']
          },
      ]
  },
  {
      title: "admin.users.title",
      subtitle: "admin.users.subtitle",
      items: [
          {
              icon: "audiences",
              route: routes['admin-users']
          },
          {
              icon: "capacity",
              route: routes['admin-roles']
          },
      ]
  }
]
